/* eslint-disable no-undef */
import * as Sentry from '@sentry/browser';
import { config } from '../../config';

// version and environment are defined in the webpack.define plugin
const release = SERVICE_VERSION;
const environment = SERVICE_ENVIRONMENT;

const isProduction = environment === 'production';

// should have been called before using it here
// ideally before even rendering your react app
Sentry.init({
    dsn: isProduction ? config.sentryToken : null,
    ignoreErrors: [
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        "Can't find variable: Intl", // Safari 9 and Safari 9 Mobile, all others have this capability
        'Cannot find function supportedLocalesOf in object function NumberFormat',
        'Non-Error promise rejection captured',
    ],
    environment,
    release,
});

export const reportErrorToSentry = (...args) => {
    if (isProduction && !document.referrer.includes('localhost')) {
        Sentry.captureException(...args);
    } else {
        console.error(...args);
    }
};
