export enum StringUrlParameters {
    productId = 'productId',
    assetIds = 'assetIds',
    resourceIds = 'resourceIds',
    locale = 'locale',
    internalCampaign = 'internalCampaign',
    level = 'level',
}

export enum BooleanUrlParameters {
    noPrice = 'noPrice',
    noButtonText = 'noButtonText',
    notResponsive = 'notResponsive',
    textOnly = 'textOnly',
    buttonLeft = 'buttonLeft',
    noButton = 'noButton',
}

export type UrlParameters = { [key in StringUrlParameters]?: string } & { [key in BooleanUrlParameters]?: boolean };
