import * as t from 'io-ts';

const periodRegex = /^P\d{1,2}[MYD]$/;
const PeriodCodec = new t.Type<string, string, unknown>(
    'period',
    (input: unknown): input is string => typeof input === 'string',
    (input, context) =>
        typeof input === 'string' && periodRegex.test(input) ? t.success(input) : t.failure(input, context),
    t.identity
);

export const ApiProductDetailsCodec = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        bookable: t.boolean,
        product_type: t.string,
    }),
    t.partial({
        level: t.string,
        currency: t.string,
        price: t.number,
        contract_period: PeriodCodec,
        variant_price_from: t.number,
    }),
]);

export type ApiProductDetails = t.TypeOf<typeof ApiProductDetailsCodec>;
