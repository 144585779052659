import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE, getLanguageData, getLocale, LanguageDataInterface } from '../../configuration';

import { State } from '../../types';
import { connect } from 'react-redux';
import { BuyButtonWrapper } from '../buybutton/BuyButtonWrapper';
import { reportErrorToSentry } from '../../configuration/setup/sentry';

interface AppPropertiesFromState {
    languageData: LanguageDataInterface;
    userLocale: string;
}

interface IntlError {
    code : string | undefined;
}

const intlErrorHandler = (error: IntlError) => {
    // Ignore errors like 'Missing locale data for locale XY. Using default locale "en-GB" as fallback.'
    if (error.code !== 'MISSING_DATA') {
        reportErrorToSentry(error);
    }
};

function App(props: AppPropertiesFromState) {
    const { languageData, userLocale } = props;

    return (
        <IntlProvider
            defaultLocale={DEFAULT_LOCALE}
            key={userLocale}
            locale={userLocale}
            messages={languageData}
            onError={intlErrorHandler}
        >
            <BuyButtonWrapper userLocale={userLocale} />
        </IntlProvider>
    );
}

const mapStateToProps = (state: State): AppPropertiesFromState => {
    return {
        languageData: getLanguageData(state),
        userLocale: getLocale(state),
    };
};

export const AppContainer = connect(mapStateToProps)(App);
