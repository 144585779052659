import { BooleanUrlParameters, StringUrlParameters, UrlParameters } from './UrlParameters';

export function extractUrlParameters(url: string): UrlParameters {
    const splitUrl = url.split('?');
    if (splitUrl.length < 2) {
        return {};
    }
    const paramsObject: UrlParameters = {};
    const params = splitUrl[1];
    const listOfParams = params.split('&');
    listOfParams.forEach((keyValue) => {
        const keyValueTuple = keyValue.split('=');
        const key = keyValueTuple[0];
        if (key in StringUrlParameters && keyValueTuple.length > 1) {
            paramsObject[key as StringUrlParameters] = keyValueTuple[1];
        } else if (key in BooleanUrlParameters) {
            paramsObject[key as BooleanUrlParameters] = true;
        }
    });
    return paramsObject;
}

export function createCheckoutUrl(productId: string, assetIds?: string, level?: string, resourceIds?: string) {
    const assetIdsParam = resourceIds ? `&resourceIds=${resourceIds}` : assetIds ? `&assetIds=${assetIds}` : '';
    const levelParam = level ? `&level=${level}` : '';
    return `https://marketplace.rio.cloud/checkout/1?productId=${productId}${assetIdsParam}${levelParam}`;
}
