import { extractUrlParameters } from '../../features/buybutton/urlUtils';
import { getWindowLanguage } from './WindowWrapper';
import { localeData } from '../../features/translations/localeData';
import { DEFAULT_LOCALE } from './lang';

export function getDisplayLocale(): string {
    const { locale: queryLocale } = extractUrlParameters(window.location.href);

    const localeToNormalize = queryLocale ? queryLocale : getWindowLanguage();

    const truncatedLocale = localeToNormalize.substr(0, 5);
    if (Object.keys(localeData).includes(truncatedLocale)) {
        return truncatedLocale;
    }
    const shortLocale = truncatedLocale.substr(0, 2);
    if (Object.keys(localeData).includes(shortLocale)) {
        return shortLocale;
    }

    return DEFAULT_LOCALE;
}
