import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { main, store } from './configuration';

import { ErrorBoundary } from './features/app/ErrorBoundary';
import { AppContainer } from './features/app/App';

const renderApplication = () => {
    createRoot(document.getElementById('root') as HTMLElement).render(
        <ErrorBoundary>
            <Provider store={store}>
                <AppContainer />
            </Provider>
        </ErrorBoundary>
    );
};

main(renderApplication);
