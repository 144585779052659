export enum LENGTH_UNIT {
    YEAR = 'years',
    MONTH = 'months',
    DAY = 'days',
}

export interface ContractPeriod {
    period: number;
    unit: LENGTH_UNIT;
}

export interface Product {
    productId: string;
    bookable: boolean;
    currency?: string;
    price?: number;
    level?: string;
    contractPeriod?: ContractPeriod;
    variantPriceFrom?: number;
    productType: ProductType;
}

export enum ProductType {
    ASSET = 'asset-based', USER = 'user-based'
}