import { getRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { ApiProductDetailsCodec } from './product.types';
import { mapProduct } from './productMapper';
import { config } from '../../../config';
import { Product } from './types';
import { FetchError } from '../FetchError';

const DEFAULT_LEVEL = '10';

export async function fetchProductDetails(id: string, level?: string | undefined): Promise<Product> {
    if (level) {
        return fetchProductDetailsRaw(id, level).catch(onRejected);
    }
    return fetchProductDetailsRaw(id, undefined)
        .catch((error: FetchError) => {
            if (error.statusCode === 400) {
                return fetchProductDetailsRaw(id, DEFAULT_LEVEL).catch(onRejected);
            } else {
                throw error;
            }
        })
        .catch(onRejected);
}

export async function fetchProductDetailsRaw(id: string, level?: string | undefined): Promise<Product> {
    const levelParam = level ? `?level=${level}` : '';
    return fetch(`${config.backend.MARKETPLACE_SERVICE}/public/products/${id}${levelParam}`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiProductDetailsCodec))
        .then(mapProduct);
}
