import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { rootReducer } from '../../reducers';
import { config } from '../../config';

export function configureStore(preloadedState) {
    const enableDevTools = config.id === 'env.local';
    return createStore(rootReducer(), preloadedState, enableDevTools ? composeWithDevTools() : undefined);
}

const store = configureStore();

export { store };
