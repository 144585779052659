import {ApiProductDetails} from './product.types';
import {LENGTH_UNIT, Product, ProductType} from './types';

export function mapProduct(apiProductDetails: ApiProductDetails): Product {
    let productType = ProductType.ASSET;
    if (apiProductDetails.product_type === 'user-based') {
        productType = ProductType.USER;
    }
    return {
        productId: apiProductDetails.id,
        bookable: apiProductDetails.bookable,
        price: apiProductDetails.price ?? undefined,
        currency: apiProductDetails.currency ?? undefined,
        level: apiProductDetails.level ?? undefined,
        contractPeriod: mapContractPeriod(apiProductDetails.contract_period),
        variantPriceFrom: apiProductDetails.variant_price_from,
        productType
    };
}

function mapContractPeriod(contractPeriod: string | undefined) {
    if (contractPeriod !== undefined) {
        return {
            period: getLengthPeriod(contractPeriod),
            unit: getLengthUnit(contractPeriod),
        };
    }
    return undefined;
}

function getLengthPeriod(apiLength: string): number {
    return parseInt(apiLength.substr(1, apiLength.length - 2), 10);
}

function getLengthUnit(apiLength: string): LENGTH_UNIT {
    switch (apiLength[apiLength.length - 1]) {
        case 'Y':
            return LENGTH_UNIT.YEAR;
        case 'M':
            return LENGTH_UNIT.MONTH;
        case 'D':
            return LENGTH_UNIT.DAY;
        default:
            throw Error(`Invalid contract length unit in ${apiLength}`);
    }
}
