import { DEFAULT_LOCALE, extractLanguage } from './lang/lang';
import langReducer from './lang/reducer';
import { getLanguageData, getLocale } from './lang/selectors';
import configReducer from './setup/configReducer';
import { store } from './setup/store';

function main(renderApp) {
    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    renderApp();
}

export { main, configReducer, getLanguageData, getLocale, langReducer, store, DEFAULT_LOCALE };
