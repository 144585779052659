import { trace } from '../../configuration/setup/trace';

export function formatPrice(locale: string, price: number, currency: string | undefined) {
    try {
        if (currency !== undefined) {
            return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(price);
        }
        return new Intl.NumberFormat(locale).format(price);
    } catch (e) {
        trace('Error during formatting price using Intl.NumberFormat, falling back to default format', e);
        return `${price.toFixed(2)}${currency ? ` ${currency}` : ''}`;
    }
}

const NUMBER_OF_DAYS_FOR_MONTH = 31;

export function getDayAndMonthPrice(
    price: number | undefined,
    userLocale: string,
    currency: string | undefined
): { dayPrice?: string; monthPrice?: string } {
    if (!price) {
        return {};
    }
    return {
        dayPrice: formatPrice(userLocale, price, currency),
        monthPrice: formatPrice(userLocale, price * NUMBER_OF_DAYS_FOR_MONTH, currency),
    };
}
