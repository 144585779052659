import { combineReducers } from 'redux';
import langReducer from './configuration/lang/reducer';

import configReducer from './configuration/setup/configReducer';

export const rootReducer = () =>
    combineReducers({
        config: configReducer,
        lang: langReducer,
    });
