export function getWindowLanguage(): string {
    const openMarketplaceLocale = extractLocaleFromOpenMarketplaceReferer(document.referrer);
    return openMarketplaceLocale ? openMarketplaceLocale : window.navigator.language;
}

// This code also exists in the buybutton, contact-form-frontend and the menu-web. Consider changing it at all places
export function extractLocaleFromOpenMarketplaceReferer(referer: string) {
    if (referer.startsWith('https://americas.rio.cloud')) {
        return 'pt-BR';
    }
    if (referer.startsWith('https://rio.cloud/')) {
        const openMarketplaceLocale = referer.split('/')[3];
        if (!openMarketplaceLocale) {
            return null;
        }
        const convertedLocale = countryToLocale[openMarketplaceLocale.toLowerCase()];
        return !!convertedLocale ? convertedLocale : openMarketplaceLocale;
    }
    return null;
}

const countryToLocale: Record<string, string> = {
    cz: 'cs',
    dk: 'da',
    ee: 'et',
    gr: 'el',
    no: 'nb',
    se: 'sv',
    si: 'sl',
};
