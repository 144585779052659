/* This method is copied to marketplace frontend popupService.ts.
 * If you do adjustments, also apply them in marketplace frontend. */
export function getCheckoutWindowProps(screen: { availHeight: number; availWidth: number }, windowId: string) {
    const popUpWidth = 1080;
    const popUpHeight = 830;
    const { availHeight, availWidth } = screen;
    const left = availWidth / 2 - popUpWidth / 2;
    const top = availHeight / 2 - popUpHeight / 2;

    if (availWidth < popUpWidth) {
        return {
            sizeString: undefined,
            windowName: '_blank',
        };
    }

    return {
        sizeString: `width=${popUpWidth},height=${popUpHeight},left=${left},top=${top}`,
        windowName: `checkoutPopup-${windowId}`,
    };
}
